// This applies the click event to the button, we pass btn (The button clicked) and a function (click handler) that passes it's select back to it for the selection
export const AddClick = (btn, clickHandler) => {
  btn.addEventListener(
    'click',
    e => {
      e.preventDefault();
      console.log('click');
      clickHandler(e.target); // Passes button in to action so i
    },
    false
  );
};

// So this allows you to pass the the list of buttons and returns a function that you can apply to the click handler
export const AddSelect = (buttons, css) => target => {
  buttons.forEach(btn => {
    // If the btn matches the click button apply selected class else remove it
    if (btn === target) {
      btn.classList.add(`${css}--selected`);
    } else {
      btn.classList.remove(`${css}--selected`);
    }
  });
};

export const GetButtons = selector => {
  const buttons = document.querySelectorAll(selector);
  // Returns empty array if no buttons
  // this prevents forEach loop throwing error
  if (!buttons) return [];
  return [...buttons]; // Converts to array
};

export const PersistData = (cookie, dataAttribute) => target => {
  // No need to pass contrast data attribute, just pass path to data.
  const data = target.getAttribute(dataAttribute);
  cookie.createCookie(data, 30);
};

export const ComposeClickHandler = actions => target => {
  actions.forEach(action => {
    action(target);
  });
};

export const FaqMover = () => {
  let url = window.location.pathname.split("/")
  url.forEach(u => {
    if(u == 'faqs'){
      let btn = document.querySelector('.accessibility__button')

      btn.style.left = "0"
      btn.style.right = "unset"
    }
  });
}
