export default () => {
  let centreLocator = document.querySelector('centre-locator-with-location-finder');

  if (!centreLocator) {
    return;
  }

  document.getElementById('geo-btn').addEventListener('click', function() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(geoPosition) {
          document.getElementById('venue_search_lat').value = geoPosition.coords.latitude;
          document.getElementById('venue_search_lng').value = geoPosition.coords.longitude;
          document.getElementById('venue_search_searchterm').removeAttribute('required'); 
          document.getElementById('submit_button').click(); 
          document.getElementById('venue_search_searchterm').value = 'Loading results';
        },
        function(error) {
          let errorMsg = document.getElementById('error-msg');
          errorMsg.textContent = 'Geolocation failed: ' + error.message;
          errorMsg.setAttribute('aria-hidden', 'false');
        }
      );
    } else {
      let errorMsg = document.getElementById('error-msg');
      errorMsg.textContent = 'Geolocation is not supported by this browser.';
      errorMsg.setAttribute('aria-hidden', 'false');
    }
  });
};